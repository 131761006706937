<template>
  <div class="app-container">
    <div v-loading="infoLoading">
      <el-form ref="form" :rules="rules" :model="form" label-width="150px">
        <el-form-item label="代取费用" prop="pickup_fee">
          <el-button icon="el-icon-plus" size="mini" plain @click="handelPickupFeeAdd"></el-button>

          <el-table :data="form.pickup_fee" border fit>
            <el-table-column label="名称" min-width="110" align="center">
              <template slot-scope="scope">
                <el-input v-model="scope.row.name" />
              </template>
            </el-table-column>

            <el-table-column label="价格" min-width="110" align="center">
              <template slot-scope="scope">
                <el-input-number v-model="scope.row.price" :precision="2" :min="0"></el-input-number>
              </template>
            </el-table-column>

            <el-table-column label="操作" min-width="110" align="center">
              <template slot-scope="scope">
                <el-button type="danger" size="mini" @click="handelPickupFeeDelete(scope.$index)">删除</el-button>

                <el-button type="primary" size="mini" @click="handelPickupFeeUp(scope.$index)">上移</el-button>

                <el-button type="primary" size="mini" @click="handelPickupFeeDown(scope.$index)">下移</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>

        <el-form-item label="打印机编号" prop="printer_no">
          <el-input v-model="form.printer_no"></el-input>
        </el-form-item>

        <el-form-item label="打印机密钥" prop="printer_key">
          <el-input v-model="form.printer_key"></el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="saveData" :loading="btnLoading">保存</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
  import request from "@/utils/request";
  import { mapGetters } from "vuex";

  export default {
    data() {
      return {
        infoLoading: true,
        infoQuery: {
          school_id: ""
        },
        btnLoading: false,
        form:{
          id: "",
          school_id: "",
          pickup_fee: [],
          printer_no: "",
          printer_key: "",
        },
        rules: {
          printer_no: [{ required: true, message: "打印机编号不能为空", trigger: "change" }],
          printer_key: [{ required: true, message: "打印机密钥不能为空", trigger: "change" }],
        },
      };
    },
    created() {
      this.getInfo();
    },
    computed: {
      ...mapGetters(["schools","school_id","user"])
    },
    watch: {
      school_id(newVal, oldVal) {
        this.infoQuery.school_id = newVal;
        this.getInfo();
      },
    },
    methods: {
      getInfo() {
        this.infoQuery.school_id = this.school_id;
        this.infoLoading = true;
        request({
          url: "/api/backend/pickupConfig/info",
          method: "get",
          params: this.infoQuery
        }).then(response => {
          this.resetForm()
          if (response.data) {
            this.form = response.data;
          }
          this.infoLoading = false;

          this.$nextTick(() => {
            this.$refs["form"].clearValidate() //清除校验结果
          })
        });
      },
      resetForm() {
        this.form = {
          id: "",
          school_id: "",
          pickup_fee: [],
          printer_no: "",
          printer_key: "",
        }
        this.btnLoading = false;
      },
      saveData() {
        this.form.school_id = this.school_id;
        if (!this.form.school_id) {
          this.$message({
            type: "warning",
            message: "请先选择学校"
          });
          return;
        }

        if (this.form.pickup_fee.length === 0) {
          this.$message({
            type: "warning",
            message: "代取费用不能为空"
          });
          return;
        }
        for (let index = 0; index < this.form.pickup_fee.length; index++) {
          const element = this.form.pickup_fee[index];

          if (element.name === "") {
            this.$message({
              type: "warning",
              message: "代取费用名称不能为空",
            });
            return;
          }

          if (element.price === undefined) {
            this.$message({
              type: "warning",
              message: "代取费用价格不能为空",
            });
            return;
          }
        }

        this.$refs["form"].validate((valid) => {
          if (valid) {
            this.btnLoading = true
            request({
              url: "/api/backend/pickupConfig/save",
              method: "post",
              data: this.form
            }).then(() => {
              this.btnLoading = false
              this.$message({
                type: "success",
                message: "操作成功"
              });
              this.getInfo();
            }).catch(() => {
              this.btnLoading = false
            })
          }
        });
      },
      handelPickupFeeAdd() {
        this.form.pickup_fee.push({
          name: "",
          price: 0,
        });
      },
      handelPickupFeeDelete(index) {
        this.form.pickup_fee.splice(index, 1);
      },
      handelPickupFeeUp(index) {
        if (index === 0) {
          return;
        }
        this.form.pickup_fee.splice(index - 1, 0, this.form.pickup_fee.splice(index, 1)[0]);
      },
      handelPickupFeeDown(index) {
        if (index >= this.form.pickup_fee.length - 1) {
          return;
        }
        this.form.pickup_fee.splice(index + 1, 0, this.form.pickup_fee.splice(index, 1)[0]);
      },
    }
  };
</script>

<style rel="stylesheet/scss" lang="scss" scoped>

</style>
